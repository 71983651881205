import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["element", "input"];

  connect() {
    if (!this.hasElementTarget || !this.hasInputTarget) {
      return;
    }

    this.sanitizerElement = document.createElement("div");

    // Because Firefox is a stubborn bastard about supporting
    // plaintext contenteditable fields
    const contenteditableType = navigator.userAgent.includes("Firefox")
      ? "true"
      : "plaintext-only";

    this.inputTarget.dataset.originalType = this.inputTarget.type;
    this.inputTarget.type = "hidden";
    this.elementTarget.setAttribute("contenteditable", contenteditableType);
    this.elementTarget.dataset.placeholder = this.inputTarget.placeholder;
    this.elementTarget.innerText = this.inputTarget.value;
    if (this.elementTarget.autofocus) {
      window.setTimeout(() => {
        this.elementTarget.dispatchEvent(new Event("click"));
      }, 100);
    }

    this.elementTarget.addEventListener("input", this.updateInput.bind(this));
    this.updateInput(null, false);
  }

  disconnect() {
    this.elementTarget.removeAttribute("contenteditable");
    this.elementTarget.innerText = "";
    this.inputTarget.type = this.inputTarget.dataset.originalType;

    this.inputTarget.removeEventListener("input", this.setInputValue);
  }

  updateInput(evt, cascadeEvent = true) {
    if (this.elementIsEmpty) {
      this.elementTarget.innerText = "";
      this.inputTarget.value = "";
      return;
    }

    const str = this.sanitizedElementString;

    // Replace HTML entities if they're there.
    if (str !== this.elementTarget.innerText) {
      this.elementTarget.innerText = str;
      this.moveCursorToEnd();
    }
    this.inputTarget.value = str;
    if (cascadeEvent) {
      this.inputTarget.dispatchEvent(new Event("input"));
    }
  }

  moveCursorToEnd() {
    if (document.activeElement !== this.elementTarget) {
      return;
    }
    const range = document.createRange();
    const selection = window.getSelection();
    range.setStart(this.elementTarget, this.elementTarget.childNodes.length);
    range.collapse(true);
    selection.removeAllRanges();
    selection.addRange(range);
  }

  get placeholderText() {
    return this.inputTarget.placeholder;
  }

  get placeholderIsVisible() {
    return this.placeholderText === this.elementTarget.innerText;
  }

  get elementIsEmpty() {
    return this.elementTarget.innerText.trim() === "";
  }

  get sanitizedElementString() {
    const str = this.elementTarget.innerText
      .replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, "") // Strip out script tags
      .replace(/ |&nbsp;|&#x202F;|&#8239;/, " ") // Strip out non-breaking spaces
      .replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, ""); // Strip out other tags.
    this.sanitizerElement.innerHTML = str;
    return this.sanitizerElement.textContent;
  }
}
